import {Email, Facebook, Instagram, KeyboardArrowRightRounded, LinkedIn, Menu, Phone} from "@mui/icons-material";
import {NavLink} from "react-router-dom";
import * as React from "react";
import {useCurrentInstance} from "../../services/current-instance/use-current-instance";

import "./sidebar.scss";
import {Box} from "@mui/material";

export default function SideBar({isOpen, onNavigateToAlbum, onNavigateToPage, onToggle}) {
    const {albums, instance} = useCurrentInstance();

    const handleToggle = () => {
        if (onToggle) {
            onToggle();
        }
    }
    const supportedContactTypes = {
        'email': {
            icon: <Email/>,
            toHref: (value) => `mailto:${value}`
        },
        'instagram': {
            icon: <Instagram/>,
            toHref: (value) => value
        },
        'facebook': {
            icon: <Facebook/>,
            toHref: (value) => value
        },
        'linkedIn': {
            icon: <LinkedIn/>,
            toHref: (value) => value
        },
        'phone': {
            icon: <Phone/>,
            toHref: (value) => `tel:${value}`
        }
    }
    return <>
        <div id="sidebarButton" onClick={handleToggle}>
            <Menu/>
        </div>
        <div id="sidebar" className={`${(isOpen ? "sidebar-open" : "sidebar-closed")}`}>
            <div className="sidebar">
                <NavLink onClick={() => onNavigateToAlbum(null)} to="/portfolio" className="home">Home</NavLink>
                {albums.map(a =>
                    <NavLink className="nav-link" onClick={() => onNavigateToAlbum(a)} key={a.albumId} to={a.albumId}>
                        <KeyboardArrowRightRounded/> {a.name}
                    </NavLink>
                )}
                {instance?.pages && <Box sx={{marginTop: '25px;'}}>
                    {Object.values(instance.pages).map(page => <NavLink className="nav-link" key={page.pageId} onClick={() => onNavigateToPage(page.pageId)} to={`pages/${page.pageId}`}>
                        {page.title}
                    </NavLink>)}
                </Box>}
                {instance && <Box sx={{marginTop: '45px;'}} id="contact-information">
                    {Object.entries(instance.contactDetails).map(([key, value]) => <>
                        {value &&
                            <NavLink className="contact-detail-link" target="_blank"
                                     to={supportedContactTypes[key].toHref(value)}>{supportedContactTypes[key].icon}</NavLink>}
                    </>)}
                </Box>}
                {instance?.otherContactDetails && <Box sx={{marginTop: '15px;'}} id="other-contact-information">
                    {instance.otherContactDetails.map(cd =>
                        <NavLink className="contact-detail-link" target="_blank" to={cd}>{cd}</NavLink>)
                    }
                </Box>}
            </div>
        </div>
        <div id="sidebar-mobile" className={`sidebar ${(isOpen ? "sidebar-open" : "sidebar-closed")}`}>
            <NavLink onClick={() => onNavigateToAlbum(null)} to="/portfolio" className="home">Home</NavLink>
            {albums.map(a => <NavLink className="nav-link" onClick={() => onNavigateToAlbum(a)} key={a.albumId}
                                      to={a.albumId}><KeyboardArrowRightRounded/> {a.name}</NavLink>)}
            {instance?.pages && <Box sx={{marginTop: '25px;'}}>
                {Object.values(instance.pages).map(page => <NavLink className="nav-link" key={page.pageId} onClick={() => onNavigateToPage(page.pageId)} to={`pages/${page.pageId}`}>
                    {page.title}
                </NavLink>)}
            </Box>}
            {instance && <Box sx={{marginTop: '45px;'}} id="contact-information">
                {Object.entries(instance.contactDetails).map(([key, value]) => <>
                    {value &&
                        <NavLink className="contact-detail-link" target="_blank"
                                 to={supportedContactTypes[key].toHref(value)}>{supportedContactTypes[key].icon}</NavLink>}
                </>)}
            </Box>}
            {instance?.otherContactDetails && <Box sx={{marginTop: '15px;'}} id="other-contact-information">
                {instance.otherContactDetails.map(cd =>
                    <NavLink className="contact-detail-link" target="_blank" to={cd}>{cd}</NavLink>)
                }
            </Box>}
        </div>
    </>;
}