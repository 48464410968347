import * as React from "react";

import "./public.scss"
import {useLayoutEffect, useState} from "react";
import {Outlet, useLocation} from "react-router-dom";
import {PageTransitionContext} from "./context/pageTransition";
import {PhotoViewer} from "../components/photo-viewer/photo-viewer";
import Loader from "../components/Loader";
import {useCurrentInstance} from "../services/current-instance/use-current-instance";
import SideBar from "../components/sidebar/sidebar";

export default function PublicLayout() {
    const {isLoading, apiError, selectedAlbum, setSelectedAlbum} = useCurrentInstance();

    const [sidebarIsOpen, setSidebarIsOpen] = useState(false);
    const [mounted, setMounted] = useState(false);
    const pageTransition = {
        mounted, setMounted
    }
    // const fetchedRef = useRef(false);

    const location = useLocation();
    useLayoutEffect(() => {
        setMounted(false);
        setTimeout(() => {
            setMounted(true);
        }, 10);
    }, [location.pathname]);

    const handleClosePhotoViewer = () => {
        setSelectedAlbum(null);
    }

    const handleNavigateToAlbum = (album) => {
        setSelectedAlbum(album);
        setSidebarIsOpen(false);
    }

    const handleNavigateToPage = (_) => {
        setSidebarIsOpen(false);
    }
    const handleSidebarToggle = () => {
        setSidebarIsOpen(!sidebarIsOpen);
    }

    return <PageTransitionContext.Provider value={pageTransition}>
        {selectedAlbum && <PhotoViewer album={selectedAlbum} onClose={handleClosePhotoViewer} />}
            <div id="publicPage">
                <main id="page">
                    <Loader loading={isLoading} />
                    {!isLoading && <>
                        <SideBar isOpen={sidebarIsOpen} onNavigateToAlbum={handleNavigateToAlbum} onNavigateToPage={handleNavigateToPage} onToggle={handleSidebarToggle} />
                        <div id={"page-content"} className={`${(sidebarIsOpen ? "sidebar-open" : "sidebar-closed")} ${(pageTransition.mounted ? "mounted" : "unmounted")}`}>
                            {apiError && <div className="error-box">{apiError}</div>}
                            {!apiError && <Outlet />}
                        </div>
                    </>}
                </main>
            </div>
    </PageTransitionContext.Provider>
}