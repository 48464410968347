import * as React from "react";
import {createRef, useEffect, useRef, useState} from "react";

import "./photo-viewer.scss"
import {ArrowLeft, ArrowRight, Close} from "@mui/icons-material";
import {Box} from "@mui/material";

export function PhotoViewer({album, onClose}) {
    const elPhotoViewer = createRef();

    const [currentIndex, setCurrentIndex] = useState(0);  // Track which photo is shown
    const [isDragging, setIsDragging] = useState(false);  // Track if swipe is in progress
    const [offset, setOffset] = useState(0);              // Track the drag offset
    const startX = useRef(0);                             // Track touch start position

    const hasNext = currentIndex < (album.photos.length || 0) - 1;
    const hasPrevious = currentIndex > 0;

    useEffect(() => {
        if (elPhotoViewer.current) {
            elPhotoViewer.current.focus();
        }
    }, [elPhotoViewer]);

    const handleClick = (e) => {
        if (e.target === elPhotoViewer.current) {
            onClose();
        }
    }

    const handleGoPrevious = () => {
        if (hasPrevious) {
            setCurrentIndex(currentIndex - 1);
        }
    }
    const handleGoNext = () => {
        if (hasNext) {
            setCurrentIndex(currentIndex + 1);
        }
    }
    const handleKeyUp = (e) => {
        console.log("Handling key event");
        switch (e.code) {
            case "ArrowLeft":
                handleGoPrevious();
                break;
            case "ArrowRight":
                handleGoNext();
                break;
            case "Escape":
                onClose();
                break;
            default:
                break;
        }
    }
    const handleClose = () => {
        if (onClose) {
            onClose();
        }
    }

    // Handle touch start
    const handleTouchStart = (e) => {
        startX.current = e.touches[0].clientX;
        setIsDragging(true);
    };

    // Handle touch move
    const handleTouchMove = (e) => {
        if (!isDragging) return;
        const currentX = e.touches[0].clientX;
        setOffset(currentX - startX.current);
    };

    // Handle touch end
    const handleTouchEnd = () => {
        if (!isDragging) return;
        setIsDragging(false);

        // Determine if we should move to the next or previous photo
        if (offset > 100 && currentIndex > 0) {
            setCurrentIndex(currentIndex - 1);
        } else if (offset < -100 && currentIndex < album.photos.length - 1) {
            setCurrentIndex(currentIndex + 1);
        }
        setOffset(0); // Reset offset after swipe
    };
    return <>
        {album && <div tabIndex={1} ref={elPhotoViewer} id="photoViewer" onClick={handleClick} onKeyUp={handleKeyUp}>
            <div className="photo-slider">
                <div
                    className="photo-container"
                    style={{
                        transform: `translateX(calc(-${currentIndex * 100}% + ${offset}px))`,
                        transition: isDragging ? "none" : "transform 0.3s ease-in-out"
                    }}
                    onTouchStart={handleTouchStart}
                    onTouchMove={handleTouchMove}
                    onTouchEnd={handleTouchEnd}
                >
                    {album.photos.map((photo, index) => (
                        <Box className="photo" key={index}>
                            <Box sx={{flex: '0 0 64px'}} className="goto-btn goto-btn--previous" onClick={handleGoPrevious}>
                                {index > 0 && <ArrowLeft sx={{fontSize: '64px'}} />}
                            </Box>
                            <img src={photo.url} alt={photo.name}/>
                            <Box sx={{flex: '0 0 64px'}} className="goto-btn goto-btn--next" onClick={handleGoNext}>
                                {index < album.photos.length - 1 && <ArrowRight  sx={{fontSize: '64px'}} />}
                            </Box>
                        </Box>
                    ))}
                </div>
            </div>
            <div className="close-btn" onClick={handleClose}>
                <Close/>
            </div>
        </div>}
    </>
}