import {useCurrentInstance} from "../../services/current-instance/use-current-instance";

import {HomeLayout1} from "../../components/home-layouts/home-layout1";
import {HomeLayout2} from "../../components/home-layouts/home-layout2";
import {HomeLayout3} from "../../components/home-layouts/home-layout3";

export function HomePage() {
    const {instance} = useCurrentInstance();
    const homeLayouts = {
        "layout-1": <HomeLayout1 instance={instance} />,
        "layout-2": <HomeLayout2 instance={instance} />,
        "layout-3": <HomeLayout3 instance={instance} />
    };
    return <>
        {instance && homeLayouts.hasOwnProperty(instance.homeLayout) && homeLayouts[instance.homeLayout]}
        {instance && !homeLayouts.hasOwnProperty(instance.homeLayout) && <HomeLayout2 instance={instance} />}
    </>;
}