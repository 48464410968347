import {useCurrentInstance} from "../../services/current-instance/use-current-instance";
import {useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import {Box, Container} from "@mui/material";

export default function InformationPage() {
    const {instance} = useCurrentInstance();
    const {pageId} = useParams();
    const [currentPage, setCurrentPage] = useState(null);

    useEffect(() => {
        if(instance && pageId && instance.pages[pageId]){
            setCurrentPage(instance.pages[pageId]);
        }
    }, [pageId, instance])

    return <Container sx={{textAlign: 'center'}}>
        {currentPage && <>
            {currentPage.coverPhoto && <Box sx={{width: `${currentPage.coverPhoto.width || 450}px`, margin: '0 auto', overflow: 'hidden', display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
                <img src={`${currentPage.coverPhoto.url}?width=${currentPage.coverPhoto.width || 450}&height=${currentPage.coverPhoto.height || 150}&fit=cover`} alt="coverPhoto" />
            </Box>}
            <h1>{currentPage.title}</h1>
            {currentPage.blocks.map((block, idx) => <Box key={idx}>
                <h2>{block.title}</h2>
                <div dangerouslySetInnerHTML={{__html: block.content}}/>
            </Box>)}
        </>}
    </Container>
}