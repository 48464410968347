import * as React from "react";
import {ImageSearch} from "@mui/icons-material";

import {useCurrentInstance} from "../../services/current-instance/use-current-instance";

function Portfolio() {
    const {instance, albums, setSelectedAlbum} = useCurrentInstance();

    return instance && <div id="page-portfolio" className="page-outlet">
        <div className="page-title">
            <div className="title">
                {instance.title}
            </div>
        </div>
        {albums?.length > 0 && <div id="album-list" className="photo-grid-layout">
            {albums.map((album) =>
                <div className="album grid-cell" key={album.albumId}>
                    <div onClick={() => setSelectedAlbum(album)} className={`thumbnail ${(album.coverPhoto ? "" : "thumbnail--no-img")}`}>
                        {album.coverPhoto && <img src={`${album.coverPhoto.url}?width=800&height=800&fit=cover`} alt={album.coverPhoto.name} />}
                        {!album.coverPhoto && <ImageSearch />}
                    </div>
                    <div className="details">
                        <div className="name">
                            {album.name}
                        </div>
                    </div>
                </div>
            )}
        </div>}
    </div>;
}
export default Portfolio;