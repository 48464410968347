import {Photo} from "./photo";

export class Instance {
    constructor(instance) {
        this._id = instance?._id;
        this.instanceId = instance?.instanceId;
        this.title = instance?.title;
        this.subtitle = instance?.subtitle;
        this.homeLayout = instance?.homeLayout;
        this.theme = instance?.theme;
        this.coverPhoto = instance?.coverPhoto ? new Photo(instance.coverPhoto) : null;
        this.urls = instance?.urls?.map(u => u) || [];
        this.contactDetails = instance?.contactDetails || {};
        this.otherContactDetails = instance?.otherContactDetails || [];
        this.pages = {};
        for (const page of Object.values(instance?.pages || {})) {
            this.pages[page.pageId] = new Page(page);
        }
    }
}

export class Page {
    /**
     * @param {Page} page
     */
    constructor(page) {
        this.pageId = page?.pageId;
        this.title = page?.title;
        this.coverPhoto = page?.coverPhoto ? new Photo(page.coverPhoto) : null;
        this.enabled = page?.enabled;
        this.blocks = page?.blocks?.map(b => new Block(b)) || [];
    }
}

export class Block {
    /**
     * @param {Block} block
     */
    constructor(block) {
        this.type = block?.type;
        this.title = block?.title;
        this.content = block?.content;
    }
}

export const BlockTypes = {
    TEXT: "text",
    TITLE_AND_TEXT: "titleAndText",
    IMAGE: "image",
    MuiDivider: "divider"
}