export class Photo {
    /**
     * @param {Photo} photo
     */
    constructor(photo) {
        this.photoId = photo?.photoId;
        this.albumId = photo?.albumId;
        this.name = photo?.name;
        this.fileName = photo?.fileName;
        this.mimeType = photo?.mimeType;
        this.s3Path = photo?.s3Path;
        this.description = photo?.description;
        this.sortOrder = photo?.sortOrder;
        this.url = `${window._env_.REACT_APP_API_URL}/api/s3/${this.s3Path}`;
        this.width = photo?.width;
        this.height = photo?.height;
    }

    /** @type {string} **/
    photoId;
    /** @type {string} **/
    albumId;
    /** @type {string} **/
    name;
    /** @type {string} **/
    s3Path;
    /** @type {string} **/
    description;
    /** @type {number} **/
    sortOrder;
    /** @type {?number} **/
    width;
    /** @type {?number} **/
    height;
}